// General reusuable helper functions
import { decodeJwt } from 'jose'
import { COOKIE_KEY } from '@/lib/auth/cookieKey'

// Helper function to coerce values to string arrays
export const toArray = (
  value: string | string[] | null | undefined
): string[] => {
  if (Array.isArray(value)) {
    return value
  } else if (value !== undefined && value !== null) {
    return [value]
  } else {
    return []
  }
}

// Retrieves the current Git commit SHA as a short hash.
export const getCommitSha = (): string | null => {
  try {
    return require('child_process')
      .execSync('git rev-parse HEAD')
      .toString()
      .trim()
      .slice(0, 8)
  } catch (e) {
    return null
  }
}

// Extracts the JWT token from the cookie header
export const getJWTFromCookies = (cookieHeader: string): string | null => {
  const cookieMatch = cookieHeader.match(
    new RegExp(`(^|;\\s*)${COOKIE_KEY}=([^;]*)`)
  )
  return cookieMatch ? decodeURIComponent(cookieMatch[2]) : null
}

// Decodes a JWT token
export const decodeJWT = (jwt: string | null): any => {
  try {
    return jwt ? decodeJwt(jwt) : null
  } catch (error) {
    console.error('Failed to decode JWT:', error)
    return null
  }
}

/**
 * Takes raw user-related data from headers or other sources and builds
 * a structured user payload. Handles type coercion and ensures defaults
 * are in place where fields might be missing.
 *
 * @param {Object} params - Raw data for the user and their organization.
 * @returns {Object} - Normalized user payload ready for use.
 */
export const parseUserPayload = ({
  authToken,
  knoxAuthToken,
  userId,
  userEmail,
  o2xId,
  orgId,
  orgName,
  orgLogo,
  firstName,
  lastName,
  profileImage,
  isStaff,
}: {
  authToken?: string | null
  knoxAuthToken?: string | null
  userId?: string | null
  userEmail?: string | null
  o2xId?: string | null
  orgId?: string | null
  orgName?: string | null
  orgLogo?: string | null
  firstName?: string | null
  lastName?: string | null
  profileImage?: string | null
  isStaff?: string | null
}) => ({
  authToken: authToken || '',
  knoxAuthToken: knoxAuthToken || '',
  user: {
    id: parseInt(userId || '0', 10),
    email: userEmail || '',
    o2x_id: o2xId || '',
    organizations_organization: {
      id: parseInt(orgId || '0', 10),
      name: orgName || '',
      logo: orgLogo || null,
    },
    first_name: firstName || null,
    last_name: lastName || null,
    profile_image: profileImage || null,
    is_staff: isStaff === 'true',
  },
})
