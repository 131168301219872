'use client';globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"vOvE3ghkVUsMHUL1lD84s"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a user loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import { getCommitSha } from '@/utils/helperFunctions'

const packageJson = require('./package.json')

// Validate Release
const release = `${packageJson.version}-${getCommitSha()}`

if (process.env.NEXT_PUBLIC_ENABLE_SENTRY === 'true') {
  // Sentry Initialization
  Sentry.init({
    dsn: process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT || 'production',
    release,
    autoSessionTracking: true,
    tracesSampleRate:
      process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT === 'production' ? 0.2 : 1.0,
    replaysSessionSampleRate:
      process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT === 'production' ? 0.2 : 1.0,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      Sentry.replayIntegration(),
      Sentry.feedbackIntegration({
        colorScheme: 'system',
      }),
    ],
    debug: false, // Debug disabled for production
    attachStacktrace: true, // Retain for user feedback and debugging
    beforeSend(event) {
      return event // Minimal processing before sending
    },
  })
}
